<template>
  <span class="ml-3 orange--text"> #{{ systemId }} </span>
</template>

<style scoped></style>

<script>
export default {
  props: {
    systemId: {
      type: String,
      default: null,
    },
  },
};
</script>
